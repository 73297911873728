import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import React from "react";
import upload from "../../../../images/upload.png";
import APKImg from "../../../../images/apk-1.png";
import closeIcon from "../../../../images/action.png";

export default function ManageApp() {
  return (
    <>
      <Grid item lg={10} xs={12}>
        <Box
          sx={{
            maxWidth: "790px",

            "@media(max-width:992px)": {
              maxWidth: "100%",
            },
          }}
        >
          <Card
            variant={false}
            sx={{
              border: "1px solid #D8DAE1CC",
              borderRadius: "10px",
              overflow: "hidden",
              mb: 3,
            }}
          >
            <CardContent
              sx={{
                "&:last-child": {
                  pb: 2,
                },
              }}
            >
              <Box
                sx={{ fontWeight: "600", fontSize: "16px", color: "#0B0B0B" }}
              >
                Upload Android App
              </Box>
              <Box sx={{ fontSize: "14px", color: "#6D6D6D" }}>
                Add your android apk here, max size of the apk in 50mb
              </Box>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "2px dashed #1849D6",
                  mt: 2,
                  p: 2,
                  textAlign: "center",
                  position: "relative",
                  "& input": {
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    zIndex: 1,
                  },
                }}
              >
                <input type="file" />
                <Stack
                  direction={"column"}
                  spacing={2}
                  justifyContent={"center"}
                >
                  <Box>
                    <img alt="" src={upload} />
                  </Box>
                  <Box
                    sx={{
                      fontSize: "16px",
                      color: "#222943CC",
                      fontWeight: 500,
                    }}
                  >
                    Drag your file(s) to start uploading
                  </Box>
                  <Box>
                    <Divider sx={{ maxWidth: "200px", mx: "auto" }}>OR</Divider>
                    <Button
                      sx={{
                        borderRadius: "5px",
                        background: "#3AC4F2",
                        textTransform: "capitalize",
                        color: "#fff",
                        px: "16px",
                        fontSize: "16px",
                        fontWeight: 500,
                        mt: 1,
                        "&:hover": {
                          background: "#3AC4F2",
                        },
                      }}
                    >
                      Browse files
                    </Button>
                  </Box>
                </Stack>
              </Box>
              <Box
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#3AC4F2",
                  my: 2,
                }}
              >
                Attached APk
              </Box>
              <Box
                sx={{
                  borderRadius: "8px",
                  p: 2,
                  border: "1px solid #E7E7E7",
                  mb: 2,
                }}
              >
                <Stack
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <img src={APKImg} alt="" />
                    <Box>
                      <Box sx={{ fontSize: "14px", fontWeight: 600 }}>
                        Shoofcom android.apk
                      </Box>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#6D6D6D",
                        }}
                      >
                        Uploaded on: 03 Oct. 2024
                      </Box>
                    </Box>
                  </Stack>
                  <IconButton>
                    <img src={closeIcon} alt="" />
                  </IconButton>
                </Stack>
              </Box>
              <Box
                sx={{
                  borderRadius: "8px",
                  p: 2,
                  border: "1px solid #E7E7E7",
                  mb: 2,
                }}
              >
                <Stack
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack direction={"row"} spacing={2} alignItems={"center"}>
                    <img src={APKImg} alt="" />
                    <Box>
                      <Box sx={{ fontSize: "14px", fontWeight: 600 }}>
                        Shoofcom android.apk
                      </Box>
                      <Box
                        sx={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "#6D6D6D",
                        }}
                      >
                        Uploaded on: 03 Oct. 2024
                      </Box>
                    </Box>
                  </Stack>
                  <IconButton>
                    <img src={closeIcon} alt="" />
                  </IconButton>
                </Stack>
              </Box>
            </CardContent>
          </Card>
          <Card
            variant={false}
            sx={{
              border: "1px solid #D8DAE1CC",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <CardContent
              sx={{
                "&:last-child": {
                  pb: 2,
                },
              }}
            >
              <Box
                sx={{ fontWeight: "600", fontSize: "16px", color: "#0B0B0B" }}
              >
                IOS Link
              </Box>
              <Box sx={{ fontSize: "14px", color: "#6D6D6D" }}>
                Set your IOS app link
              </Box>
              <Box
                sx={{
                  fontSize: "18px",
                  fontWeight: "500",
                  color: "#3AC4F2",
                  mt: 2,
                  mb: 1,
                }}
              >
                Set IOS link
              </Box>
              <Box
                sx={{
                  "& input": {
                    width: "100%",
                    py: 1,
                    px: 2,
                    background: "#F4F8FB",
                    border: "1px solid #D8DAE180",
                    borderRadius: "5px",
                    outline: "none",
                    height: "50px",
                  },
                }}
              >
                <input type="text" placeholder="Paste your ios app link here" />
                <Box sx={{ textAlign: "right" }}>
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      borderRadius: "5px",
                      background: "#3AC4F2",
                      textTransform: "capitalize",
                      fontSize: "16px",

                      width: "138px",
                      mt: 1,
                      height: "50px",
                    }}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </>
  );
}
